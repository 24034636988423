import style from "./style.module.scss";
import {useNavigate} from "react-router-dom";
import {Header} from "../../components/header";
import {Footer} from "../../components/footer";

export const PageNotFound = () => {
    const navigate = useNavigate();

    return (
        <>
            <Header/>
            <div className={style.not_found_container}>
                <div>
                    <h1>Oops! Seite nicht gefunden</h1>

                    <p>
                        Es sieht so aus, als ob die Seite, die du suchst, nicht existiert.
                        Vielleicht hast du die URL falsch eingegeben, oder der Link ist nicht mehr aktuell.
                    </p>

                    <button onClick={() => navigate("/")}>
                        Zur Startseite
                    </button>
                </div>
            </div>
            <Footer/>
        </>
    );
};
