// @ts-ignore
import style from './style.module.scss';
import {Header} from "../../components/header";
import {Footer} from "../../components/footer";

export const Contact = () => {
    return (
        <>
            <Header/>

            <div className={style.contact_page}>

                <h1 className={style.section_title}><span>Kontaktieren</span> Sie uns</h1>
                <p className={style.section_description}>Haben Sie Fragen oder Anmerkungen? Schreiben Sie uns einfach
                    eine Nachricht!</p>

                <div className={style.box}>
                    <h2>Kontaktinformationen</h2>
                    <ul>
                        <li>+49 5128 - 1238</li>
                        <li>raekekfz@web.de</li>
                        <li>Kesselstraße 9 31249 Hohenhameln</li>
                    </ul>
                </div>
            </div>

            <Footer/>
        </>
    )
}